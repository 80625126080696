<template>
  <v-dialog
    v-model="dialogApprove"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">ยืนยันการชำระเงิน</span>
      </v-card-title>

      <v-card-text>
        <!--                <h5>รายการสั่งซื้อแพ็กเกจ ชำระครบจำนวนแล้ว </h5>-->
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card>
              <v-card-text>

                <!--                               data-target="#updateConfirmPay{{$mem_package_pay->mem_package_pay_id}}"-->

                <v-text-field
                  type="number"
                  min="1"
                  label="จำนวนเครดิต(ชื้น)"
                  placeholder="จำนวนเครดิต"
                ></v-text-field>
                <!--                      confirm_pay_detail-->
                <v-textarea
                  placeholder="รายละเอียด"
                  label="รายละเอียด"
                ></v-textarea>
                <span  style="color: rgb(227, 86, 83);font-size: 13px">*เมื่อระบบยืนยันการเติมแพ็กเกจเเล้วระบบจะทำการเติมเครดิตสินค้าให้ลูกค้าทันที<br> </span>

                <!--                                                                <div class="form-group">-->
                <!--                                                                    <label for="">จำนวนเครดิต(ชื้น)</label>-->
                <!--                                                                    <input type="text" min="1" value="{{$mem_package_pay->amount_credit}}"  name="amount_credit" class="form-control" id="" aria-describedby="" placeholder="จำนวนเครดิต">-->
                <!--                                                                    <input name="confirm_pay" value="Y" type="hidden">-->
                <!--                                                                </div>-->
                <!--                                                                <div class="form-group">-->
                <!--                                                                    <label for="">รายละเอียด</label>-->
                <!--                                                                    <textarea type="text" name="confirm_pay_detail" class="form-control" id="" aria-describedby="" placeholder="รายละเอียด"> {{$mem_package_pay->confirm_pay_detail}} </textarea>-->
                <!--                                                                    <input name="confirm_pay" value="Y" type="hidden">-->
                <!--                                                                    <span  style="color: rgb(227, 86, 83);font-size: 13px">*เมื่อระบบยืนยันการเติมแพ็กเกจเเล้วระบบจะทำการเติมเครดิตสินค้าให้ลูกค้าทันที<br> </span>-->
                <!--                                                                </div>-->

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="closeApprovePay"
        >
          Cancel
        </v-btn>

<!--        :disabled="editedItem.confirm_pay === 'Y'"-->
<!--        :title="editedItem.confirm_pay === 'Y' ? 'ยืนยันไปเเล้ว' : 'ยืนยัน'"-->
        <v-btn
          color="primary"
          text
          @click="approvePayConfirm()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline} from '@mdi/js'
import Datepicker from "vue2-datepicker";
import {ref} from '@vue/composition-api'



export default {
  components: {
    Datepicker
    // backend
    // AccountSettingsAccount,
    // AccountSettingsSecurity,
    // AccountSettingsInfo,
  },
  props : ['editedItem','dialogApprove','closeApprovePay','approvePayConfirm'],
  setup() {

    return {
      // tab,
      // tabs,
      // accountSettingData,
      // icons: {
      //   mdiAccountOutline,
      //   mdiLockOpenOutline,
      //   mdiInformationOutline,
      // },
    }
  },
  data() {
    return {

    }
  },
  methods: {
  },
  mounted() {

  }
}
</script>
